/*General*/
.gcp-link,
.gcp-link:hover {
  color: #ff7043;
  display: inline-flex;
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
}

.gcp-button,
.gcp-button:hover {
  background-color: #FF8000;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  font-weight: 600;
  text-decoration: none;
  border: none;
  padding: 13px 30px;
  text-decoration: none;
}

.card {
  border: 1px solid #d3d3d3;
  border-radius: 2px;
}

.form-control:focus {
  border-color: #c63f17;
  box-shadow: none;
}

:focus {
  outline: none;
}

.center {
  text-align: center;
}

a.gcp, a:hover.gcp {
  color: #FF8000 !important;
  font-weight: 600;
}

.title.center + .mustache {
    margin-left: auto;
    margin-right: auto;
}

.mustache {
    color: #FF8000;
    width: 150px;
    border-bottom: 1px solid;
    border-width: 3px;
    margin-top: 24px;
    margin-bottom: 48px;
}

.section.no-bottom: {
  padding-bottom: 0;
}

.about {
  position: static !important; 
  height: auto !important;
  display: block !important; 
  flex-direction: row !important;
}

/*Section*/
.section {
  padding: 50px 0;
}

@media (min-width: 768px) {
  .section {
    padding: 50px 0;
  }
}

.section h1 {
  color: #333435;
  font-family: 'Open Sans', serif;
  font-size: 2.369em;
  font-weight: 400;
  margin: 0 0 28px 0;
}

.section h2 {
  color: #333435;
  font-family: 'Open Sans', serif;
  font-size: 2.369em;
  font-weight: 400;
  margin: 0 0 50px 0;
  text-align: center;
}

.section h3 {
  color: #333435;
  font-family: 'Open Sans', serif;  
  font-size: 1.777em;
  font-weight: 400;
  margin: 0 0 21px 0;
}


.section h4 {
  color: #333435;
  font-family: 'Open Sans', serif;  
  font-size: 1.333em;
  font-weight: 400;
  margin: 0 0 21px 0;
}

.section p {
  font-size: 1em;
  line-height: 27px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  margin: 0;
}

.section p {
  margin-bottom: 21px;
}

.section .narrow p {
  font-size: 1.333em;
  line-height: 31px;
  margin: 0 0 21px 0;
}

.section .title {
  font-weight: 600;
}

.main .container {
  max-width: 940px;
}

/*Approach*/
.approach .col-md-4 + .col-md-4 {
  margin-top: 28px;
}

@media (min-width: 6px) {
  .approach .col-md-4 + .col-md-4 {
    margin-top: 0;
  }
}

/*Activities*/
.activities .col-lg-4 {
  margin-bottom: 28px;
}

.activities .card-footer,
.news .card-footer {
  background-color: transparent;
  border: none;
  padding-bottom: 21px
}

.activities i {
  margin-left: 5px;
}

@media (min-width: 992px) {
  .activities .col-lg-4 {
    margin-bottom: 0;
  }

  .activities .card {
    min-height: 355px;
  }

  .activities .row + .row {
    margin-top: 28px;
  }  
}

@media (min-width: 1200px) {
  .activities .card {
    min-height: 300px;
  }
}


/*Header --------------------------------------------------------- */
/*Header*/
.header {
  border-bottom: 1px solid #dadada;
  padding: 8px 0;
  background-color: #fff;
}

.about .header .container-fluid {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-left {
  display: flex;
  align-items: center;
}

.header .logo {
  padding-right: 16px;
}

.header .logo img {
  width: 96px;
}

.header .secondary-logo {
  padding-left: 16px;
  border-left: 2px solid #4b2e11;;
}

.header .secondary-logo a,
.header .secondary-logo a:hover {
  text-decoration: none;
}

.header .secondary-logo .initiative {
  color: #545454;
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 1em;
  font-weight: 700;
}

.header-right .nav {
  list-style: none;
  padding: 0;
  margin: 0;
}

.header-right .nav li {
  display: inline-block;
  font-size: 0.8em;
  font-weight: 600;
  flex-shrink: 0;
}

.header-right .nav li a {
  color: #202124;
  text-decoration: none;
  cursor: pointer;  
}

@media (min-width: 576px) {
  .header .logo img {
    width: 120px;
  }

  .header .secondary-logo .initiative {
    font-size: 1.563em;
  }

  .header-right .nav li {
    font-size: 1em;
  }
}


/*Footer --------------------------------------------------------- */
.footer {
  background-color: rgba(32, 33, 36, 0.9);
}

.footer .social {
  border-top: 1px solid #dadada;
  padding: 32px 0;
}

.footer .social h5 {
  display: none;
  margin-right: 32px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
}

.footer .social ul {
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .social li {
  display: inline-block;
  font-size: 1.25em;
}

.footer .social li + li {
  margin-left: 32px;
}

.footer .social a {
  color: #dadada;
  text-decoration: none;
}

.footer .social a:hover {
  color: #fff;
  text-decoration: none;
}

.footer .activities {
  border-top: 1px solid #dadada;
  padding: 32px 0;
}

.footer .activities .col-lg-3 + .col-lg-3 {
  margin-top: 32px;
}

.footer .activities h5 {
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
}

.footer .activities ul {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer .activities li {
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  padding-top: 16px;
}

.footer .activities li a,
.footer .activities .coming-soon {
  color: #dadada;
  text-decoration: none;
  font-weight: 400;
}

.footer .activities li a:hover,
.footer .activities .coming-soon:hover {
  color: #fff;
  text-decoration: none;
  font-weight: 400;
}

.footer .activities .coming-soon {
  cursor: default;
}

.footer .brand {
  border-top: 1px solid #dadada;
  padding: 32px 0;
  text-align: center;
}

.footer .brand .logo {
  margin-bottom: 32px;
}

.footer .brand .logo img {
  width: 150px;
}

.footer .brand .partners-list .col {
  margin-bottom: 32px;
}

.footer .brand .links ul {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer .brand .links li {
  display: inline-block;
  font-size: 0.9em;
}

.footer .brand .links li + li {
  margin-left: 24px;
}

.footer .brand .links li a {
  color: #dadada;
  text-decoration: none;
}

.footer .brand .links li a:hover {
  color: #fff;
  text-decoration: none;
}

@media (min-width: 576px) {
  .footer .social h5 {
    display: inline-block;
  }
}

@media (min-width: 768px) {
  .about .container-fluid {
    padding-right: 80px;
    padding-left: 80px;
  }
}

@media (min-width: 992px) {
  .footer .activities .col-lg-3 + .col-lg-3 {
    margin-top: 0;
  }
}
